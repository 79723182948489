import React from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';

import { Head } from '../components/templates/head';

import { Header } from '../components/molecules/header';
import { Button } from '../components/atoms/button';
import { CloseButton } from '../components/atoms/close-button';
import { PhoneNumber } from '../components/atoms/phone-number';

import HomeIcon from '../assets/home.svg';
import RestartIcon from '../assets/restart.svg';

// markup
const NotFoundPage = () => {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
					}
				}
			}
		`
	);

	return (
		<>
			<Head title={`${site.siteMetadata.title} - Not Found`} />
			<main id="main" className="container mx-auto bg-brand-pale-300">
				<section className="px-4 pt-6">
					<div className="flex justify-between wrapper-small">
						<Header
							backBtn={
								<CloseButton
									destination="https://www.peninsulagrouplimited.com/"
									className="!py-xxs-f !px-l-f"
									id="peninsula404"
									ariaLabel="Go to the Peninsula homepage"
								>
									<HomeIcon className="mr-2" />
									Peninsula
								</CloseButton>
							}
							restartBtn={
								<Button
									variant="White"
									className="!py-xxs-f !px-l-f"
									onClick={() => navigate('/')}
								>
									<RestartIcon className="mr-2" />
									Restart
								</Button>
							}
						/>
					</div>
				</section>
				<section className="p-4 my-10">
					<hgroup className="mx-auto wrapper-small">
						<h1 className="leading-tight lmb-2 text-3xl-f">
							Page not found
						</h1>
						<h2 className="text-lg-f">
							Sorry. We couldn&apos;t find what you were looking
							for.
						</h2>
					</hgroup>
				</section>
				<div className="px-4">
					<div className="mx-auto wrapper-small">
						<PhoneNumber />
					</div>
				</div>
			</main>
		</>
	);
};

export default NotFoundPage;
