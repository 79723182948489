import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button } from '../button';

const CloseButton = ({
	id,
	children,
	ariaLabel,
	className,
	promptTitle,
	promptDesc,
	destination,
	variant,
}) => {
	const [open, setOpen] = useState(false);
	const modalRef = useRef(null);

	useEffect(() => {
		if (open) {
			const firstFocusableElement =
				modalRef.current.querySelectorAll('button')[0];
			const focusableContent =
				modalRef.current.querySelectorAll('button');
			const lastFocusableElement =
				focusableContent[focusableContent.length - 1];

			document.addEventListener('keydown', (e) => {
				if (e.key === 'Escape') {
					setOpen(!open);
				}

				if (e.shiftKey) {
					if (document.activeElement === firstFocusableElement) {
						if (e.key !== 'Enter') {
							lastFocusableElement.focus();
							e.preventDefault();
						}
					}
				} else if (document.activeElement === lastFocusableElement) {
					if (e.key !== 'Enter') {
						firstFocusableElement.focus();
						e.preventDefault();
					}
				}
			});

			firstFocusableElement.focus();
		}
	}, [open]);

	const handleDestination = (e) => {
		const hasGtag = typeof window.gtag === 'function';

		e.preventDefault();

		if (hasGtag) {
			window.gtag('event', 'restart', {
				click_text: 'Restart',
				click_type: 'button',
			});
		}

		window.location.href = destination;
	};

	return (
		<>
			<Button
				variant={variant}
				aria-label={ariaLabel}
				className={className}
				onClick={() => setOpen(!open)}
			>
				{children}
			</Button>

			<div
				style={{ display: open ? 'flex' : 'none' }}
				aria-hidden={!open}
				className="fixed top-0 left-0 right-0 z-50 flex items-center justify-center w-full h-full bg-black/50"
				ref={modalRef}
			>
				<div
					role="alertdialog"
					aria-modal="true"
					aria-label={promptTitle}
					aria-describedby={`promptDesc-${id}`}
					className="max-w-md p-8 text-center bg-white border-2 border-blue-400 rounded-sm shadow flow-vertical"
				>
					<div id={`promptDesc-${id}`}>
						<p className="text-lg-f">{promptDesc}</p>
					</div>
					<div className="flow-horizontal">
						<Button
							variant="Answer"
							onClick={() => setOpen(!open)}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									e.preventDefault();
									setOpen(!open);
								}
							}}
						>
							No
						</Button>
						{/* TO DO - onClick function to be determined dependent on if the window should be closed or navigated to main website */}
						<Button
							variant="Answer"
							onClick={handleDestination}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									handleDestination(e);
								}
							}}
						>
							Yes
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

CloseButton.defaultProps = {
	ariaLabel: 'Close triage',
	className: undefined,
	promptTitle: 'Close triage',
	promptDesc: 'Are you sure you want to leave?',
	variant: 'White',
};

CloseButton.propTypes = {
	id: PropTypes.string.isRequired,
	ariaLabel: PropTypes.string,
	className: PropTypes.string,
	promptTitle: PropTypes.string,
	promptDesc: PropTypes.string,
	destination: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	variant: PropTypes.string,
};

export { CloseButton };
