import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { clsx } from 'clsx';

export const PhoneNumber = ({ hasBorder }) => {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						phoneNumber
					}
				}
			}
		`
	);
	return (
		<section
			className={clsx(
				'pt-6',
				hasBorder ? 'border-t border-t-brand-pale-500' : ''
			)}
		>
			<h2 className="mb-4 text-xl font-centra-medium">
				Call a Peninsula adviser on
			</h2>
			<p>
				<a
					href={`tel:${site.siteMetadata.phoneNumber.replaceAll(
						' ',
						''
					)}`}
					className="text-2xl leading-6 text-blue-400 phone-number font-centra-medium"
				>
					{site.siteMetadata.phoneNumber}
				</a>
				<span className="flex items-center text-sm">
					<span className="inline-block ml-1 mr-2 bg-blue-400 blue-dot shrink-0" />
					Speak to an expert 24/7
				</span>
			</p>
		</section>
	);
};

PhoneNumber.defaultProps = {
	hasBorder: true,
};

PhoneNumber.propTypes = {
	hasBorder: PropTypes.bool,
};
